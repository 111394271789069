import React from "react";
import "./Comment.css";

interface Props {
  title: string;
  name: string;
  date: string;
  content: string;
}

function Comment(props: Props) {
  const date = new Date(props.date);
  return (
    <div className="Comment">
      <p style={{ fontWeight: 600, marginBottom: 5 }}>{props.title}</p>
      <div className="Row">
        <p style={{ color: "#666" }}>{props.name}</p>
        <p style={{ color: "#666" }}>{props.date}</p>
      </div>
      <div style={{ marginBottom: 10, marginTop: 5 }}>
        <img src="./star.png" height={17} alt="star rate" />
        <img src="./star.png" height={17} alt="star rate" />
        <img src="./star.png" height={17} alt="star rate" />
        <img src="./star.png" height={17} alt="star rate" />
        <img src="./star.png" height={17} alt="star rate" />
      </div>
      <p>{props.content}</p>
    </div>
  );
}

export default Comment;
