import React from "react";
import Header from "./Header";
import Comment from "./Comment";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="userComments">
        <p className="titleReviews">Some reviews</p>
        <div className="userCommentsList">
          <Comment
            name="xxcns"
            date="05/28/2020"
            title="AMAZING"
            content="wished I knew abt this sooner lolll creates a second account on IG to do the same thing this app does for my actual IG... but omg is this app such a blessing! Just being able to drag any post where you want and seeing how it looks without having to delete and re-upload in the order I want... Hope it’s understandable, but regardless, hope you feel my enthusiasm... AMAZING"
          />
          <Comment
            name="KNReyesK"
            date="04/23/2020"
            title="Exactly what I was looking for"
            content="I had been using free apps that automatically post for me. As nice as it was to have that automation, I found out it was interring with my insta growth because insta was detecting it as a robot. So I was looking for something where I could plan out visually with content and post from it without giving my insta profile information. This has been a winner! It works smoothly and it’s safe :)"
          />
          <Comment
            name="Tiffiistheshizz"
            date="02/03/2020"
            title="First review"
            content="Never leave reviews but I love this app so much! Don’t have to log into Insta"
          />
          <Comment
            name="shi18374892"
            date="06/15/2020"
            title="SOOO HELPFUL !!"
            content="If it weren’t for this app, my feed would be completely trashhh !!"
          />
          <Comment
            name="KimEPow"
            date="05/28/2020"
            title="best preview app I’ve used so far"
            content="it’s so intuitive. I love the ease of use and that you can add multiple accounts. I use it daily to plan my layout and sometimes to schedule. no complaints here."
          />
          <Comment
            name="20Lea04"
            date="06/12/2020"
            title="Love this app 🖤"
            content="This app is great for my multiple instagram accounts. I never write reviews so this is a lot for me to actually write one."
          />
          <Comment
            name="KhoaNeo45"
            date="12/22/2019"
            title="Three Businesses - Life Simplified."
            content="I run three businesses through Feed Preview for Instagram and couldn’t imagine going back to the old way, this saves SO much time. Intuitive, simple, and easy to use. Highly recommend!"
          />
          <Comment
            name="Bellefille11"
            date="06/12/2020"
            title="Love it"
            content="So far so good. I love how I can plan my post and see my feed without being logged in"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
