import React from "react";
import "./Header.css";

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return;
}

const mobilePlatform = getMobileOperatingSystem();
const lang = navigator.language.split("-")[0].toLocaleLowerCase();
const country = navigator.language.split("-")[1].toLocaleLowerCase();
const androidStore =
  "https://play.google.com/store/apps/details?id=com.charlyberthet.instagramfeedpreview&hl=" +
  lang;
const appleStore =
  "https://apps.apple.com/" +
  country +
  "/app/feed-preview-for-instagram/id1451873163";

function Header() {
  return (
    <header className="appHeader">
      <div className="titles">
        <h1>Feed Preview for Instagram</h1>
        <h2 className="numbUsers">Used by 1 Million Instagrammers</h2>
      </div>
      <div className="details">
        <p>
          Get a <strong>stunning Instagram feed</strong> by previewing how it
          would look like in advance! Play with your feed, it’s{" "}
          <strong>free</strong> and requires <strong>no login</strong>!
        </p>
      </div>
      <div className="tutoWrapper">
        <img
          src="./iphone2.png"
          className="iphoneTuto"
          alt="feed preview for Instagram"
        />
        <video controls={false} autoPlay muted className="videoTuto" loop>
          <source src="./feed-preview-for-instagram.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="actions">
        <div className="downloadStore">
          {!mobilePlatform || mobilePlatform === "iOS" ? (
            <button
              className="downloadStoreBtn"
              onClick={() => (window.location.href = appleStore)}
            >
              <img
                src="./feedpreview-instagram-apple-store.png"
                alt="feed preview for Instagram on Apple Store"
              />
            </button>
          ) : undefined}
          {!mobilePlatform || mobilePlatform === "Android" ? (
            <button
              className="downloadStoreBtn"
              onClick={() => (window.location.href = androidStore)}
            >
              <img
                src="./feedpreview-instagram-google-play-store.png"
                alt="feed preview for Instagram on Apple Store"
              />
            </button>
          ) : undefined}
        </div>
        <p className="appRate">
          It's free and has 6.3k rating
          <span style={{ marginLeft: 5 }}>
            <img src="./star.png" height={13} alt="star rate" />
            <img src="./star.png" height={13} alt="star rate" />
            <img src="./star.png" height={13} alt="star rate" />
            <img src="./star.png" height={13} alt="star rate" />
            <img src="./star-half.png" height={13} alt="star rate" />
          </span>
        </p>
      </div>
    </header>
  );
}

export default Header;
